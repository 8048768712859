/*
 *  @TODO
 *    - Use a form
 *    - Deleting an API key
 *      - Fix issue with closing the side bar after deleting
 *    - Improve styling
 */

import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import {
  useGetLoggedUser,
  IGetLoggedUserOnCompletedResponse,
  IUser,
  useGetGeladaProject,
  IGetGeladaProjectOnCompletedResponse,
  IGeladaProject,
} from '@netfront/gelada-identity-library';
import { Button, CopyButton, Dialog, Input, Spinner, ToggleSwitch, Tooltip } from '@netfront/ui-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { GeneralViewProps } from './GeneralView.interfaces';

import { InstructionsEmailDialog } from '../../../../components';
import { useApiKeyContext } from '../../../../contexts';
import {
  IDeleteApiKeyOnCompletedResponse,
  IUpdateApiKeyOnCompletedResponse,
  useDeleteApiKey,
  useToast,
  useUpdateApiKey,
} from '../../../../hooks';
import { ApiKeyStatusType, IApiKey } from '../../../../interfaces';

const GeneralView = ({ onClose }: GeneralViewProps) => {
  const { query } = useRouter();
  const { dispatch, state: apiKeys = [] } = useApiKeyContext();
  const { handleToastError, handleToastSuccess } = useToast();

  const { projectId } = query;

  const apiKeyRef = useRef<HTMLParagraphElement>(null);
  const scriptRef = useRef<HTMLParagraphElement>(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDisplayInstructionsModal, setIsDisplayInstructionsModal] = useState<boolean>(false);
  const [loggedUser, setLoggedUser] = useState<IUser>();
  const [project, setProject] = useState<IGeladaProject>();
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey | undefined>(apiKeys.find(({ isSelected }) => isSelected));

  const handleApiKeyStatusChange = (apiKey: IApiKey, status: ApiKeyStatusType) => {
    const updatedStatus: ApiKeyStatusType = status === 'ACTIVATE' ? 'DEACTIVATE' : 'ACTIVATE';

    handleUpdateApiKey({
      id: apiKey.id,
      status: updatedStatus,
    });
  };

  const handleApiKeyMaintainChange = (apiKey: IApiKey, shouldMaintain: boolean) => {
    handleUpdateApiKey({
      id: apiKey.id,
      maintainTranslationOnNavigation: shouldMaintain,
    });
  };

  const handleApiKeyMaintainAccessibilityChange = (apiKey: IApiKey, shouldMaintain: boolean) => {
    handleUpdateApiKey({
      id: apiKey.id,
      isAccessibilityMaintainedOnNavigation: shouldMaintain,
    });
  };

  const handleApiKeyTitleChange = (event: ChangeEvent<HTMLInputElement>, apiKey: IApiKey) => {
    const {
      target: { value },
    } = event;

    const updatedApiKey: IApiKey = {
      ...apiKey,
      title: value,
    };

    setSelectedApiKey(updatedApiKey);

    handleUpdateApiKey({
      id: apiKey.id,
      title: value,
      useDebounce: true,
    });
  };

  const handleApolloError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCopyCompleted = (property: string) => {
    handleToastSuccess({
      message: `${property} copied to the clipboard`,
    });
  };

  const handleDeleteApiKeyCompleted = (data?: IDeleteApiKeyOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      dispatch({
        type: 'deleteSelectedApiKey',
      });

      setIsDeleteDialogOpen(false);
      setSelectedApiKey(undefined);

      onClose?.();

      return;
    }

    const {
      MESSAGES: {
        ERROR: { UNEXPECTED },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(UNEXPECTED),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleDeleteApiKeyError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const handleDeleteApiKeyButtonClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteApiKeyConfirmationDialogCloseClick = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteApiKeyConfirmationDialogConfirmClick = (id: number) => {
    handleDeleteApiKey({
      id,
    });
  };

  const handleGetLoggedUserCompleted = (data?: IGetLoggedUserOnCompletedResponse) => {
    if (!data) {
      return;
    }

    setLoggedUser(data.user);
  };

  const handleGetLoggedUserError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const handleToggleInstructionsModal = () => {
    setIsDisplayInstructionsModal(!isDisplayInstructionsModal);
  };

  const handleUpdateApiKeyCompleted = (data?: IUpdateApiKeyOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { apiKey: returnedApiKey } = data;

    setSelectedApiKey(returnedApiKey);

    dispatch({
      payload: {
        apiKey: returnedApiKey,
      },
      type: 'updateApiKey',
    });
  };

  const handleUpdateApiKeyError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const { handleDeleteApiKey, isLoading: isDeleteApiKeyLoading = false } = useDeleteApiKey({
    onCompleted: handleDeleteApiKeyCompleted,
    onError: handleDeleteApiKeyError,
  });

  const { handleGetLoggedUser, isLoading: isGetLoggedUserLoading = false } = useGetLoggedUser({
    onCompleted: handleGetLoggedUserCompleted,
    onError: handleGetLoggedUserError,
  });

  const { handleUpdateApiKey, isLoading: isUpdateApiKeyLoading = false } = useUpdateApiKey({
    onCompleted: handleUpdateApiKeyCompleted,
    onError: handleUpdateApiKeyError,
  });

  const handleGetGeladaProjectCompleted = (data?: IGetGeladaProjectOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { geladaProject } = data;
    setProject(geladaProject);
  };

  const { handleGetGeladaProject } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: handleGetGeladaProjectCompleted,
    onError: handleApolloError,
  });

  useEffect(() => {
    handleGetLoggedUser({
      shouldIncludeUserCredential: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetGeladaProject({
      projectId: String(projectId),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!apiKeys.length) {
      return;
    }

    setSelectedApiKey(apiKeys.find(({ isSelected }) => isSelected));
  }, [apiKeys]);

  if (!selectedApiKey) {
    return null;
  }

  const {
    creationDateLocaleDateString,
    guid,
    id,
    maintainTranslationOnNavigation: shouldMaintainTranslationOnNavigation,
    isAccessibilityMaintainedOnNavigation = false,
    status,
    statusText,
    title,
  } = selectedApiKey;

  const isLoading = isDeleteApiKeyLoading || isGetLoggedUserLoading || isUpdateApiKeyLoading;

  return (
    <>
      {loggedUser && (
        <>
          <Spinner isLoading={isLoading} />

          <Dialog
            isOpen={isDeleteDialogOpen}
            title="Delete API Key"
            onClose={handleDeleteApiKeyConfirmationDialogCloseClick}
            onConfirm={() => handleDeleteApiKeyConfirmationDialogConfirmClick(id)}
          >
            <div>
              {isDeleteApiKeyLoading ? 'Deleting ' : 'Are you sure you want to delete '}
              <span className="weight-600">{title}</span>
              {isDeleteApiKeyLoading ? '...' : '?'}
            </div>
          </Dialog>

          {project && (
            <InstructionsEmailDialog
              isApp={project.hasMobileApplication}
              isOpen={isDisplayInstructionsModal}
              loggedUser={loggedUser}
              projectName={project.name}
              selectedApiKey={selectedApiKey}
              onClose={handleToggleInstructionsModal}
              onSuccess={() => setIsDisplayInstructionsModal(false)}
            />
          )}

          <div className="flex flex-col p-4">
            <div className="flex flex-1 flex-col">
              <section className="pb-8">
                <h3 className="mb-2">API title</h3>

                <Input
                  id="api-key-title"
                  labelText=""
                  name="api-key-title"
                  type="text"
                  value={title}
                  onChange={(event) => handleApiKeyTitleChange(event, selectedApiKey)}
                />
              </section>

              <section className="pb-8">
                <h3 className="mb-2">API key</h3>

                <div className="flex items-center">
                  <div ref={apiKeyRef} className="flex flex-1">
                    {guid}
                  </div>
                  <div className="flex pl-4">
                    <CopyButton
                      copyElementRef={apiKeyRef}
                      supportiveText="Copy the API key"
                      onCopyCompleted={() => handleCopyCompleted('API key')}
                    />
                  </div>
                </div>
              </section>

              <section className="pb-8">
                <div>
                  <span className="h3 weight-700 mb-2">Script</span>
                  <p>
                    To get help installing, see our help
                    <NextLink href="/docs/installation" rel="noopener noreferrer" target="_blank">
                      <span className="color-primary" >
                        &nbsp;docs&nbsp;
                      </span>
                    </NextLink>
                    or
                    <span className="border-none color-primary pointer" onClick={handleToggleInstructionsModal}>
                      &nbsp;send instructions
                    </span>
                  </p>
                  <strong>Paste this code before &lt;/body&gt; tag:</strong>
                </div>

                <div className="flex items-center">
                  <div ref={scriptRef} className="flex flex-1">
                    {`<script id="kanzi" data-defaultlanguage="en" data-apikey="${guid}" src="https://plugin.kanzi.io/lib/kanzi.js" async></script>`}
                  </div>
                  <div className="flex pl-4">
                    <CopyButton
                      copyElementRef={scriptRef}
                      supportiveText="Copy the script"
                      onCopyCompleted={() => handleCopyCompleted('Script')}
                    />
                  </div>
                </div>
              </section>

              <section className="pb-8">
                <h3 className="mb-2">Created by</h3>

                <div>
                  {loggedUser.firstName} {loggedUser.lastName} - {creationDateLocaleDateString}
                </div>
              </section>

              <section className="pb-8">
                <div className="flex flex-col">
                  <div className="flex items-center ">
                    <h3 className="mb-2 flex-1">Maintain translation</h3>
                    <Tooltip
                      additionalClassNames="c-style-settings__tooltip"
                      iconId="id_tooltip_icon"
                      placement="left"
                      text="If selected this will maintain the selections of the user across pages including the translation language"
                    />
                  </div>

                  <div className="flex items-center">
                    <p className="flex flex-1 mb-0">Maintain user language selection across pages</p>
                    <div className="flex pl-4">
                      <ToggleSwitch
                        additionalClassNames="ml-auto"
                        id="maintainTranslationOnNavigation"
                        isChecked={shouldMaintainTranslationOnNavigation}
                        labelText=""
                        onChange={() => handleApiKeyMaintainChange(selectedApiKey, !shouldMaintainTranslationOnNavigation)}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="pb-8">
                <div className="flex flex-col">
                  <div className="flex items-center ">
                    <h3 className="mb-2 flex-1">Maintain accessibility</h3>
                    <Tooltip
                      additionalClassNames="c-style-settings__tooltip"
                      iconId="id_tooltip_icon"
                      placement="left"
                      text="If selected this will maintain accessibility across pages."
                    />
                  </div>

                  <div className="flex items-center">
                    <p className="flex flex-1 mb-0">Maintain accessibility across pages</p>
                    <div className="flex pl-4">
                      <ToggleSwitch
                        additionalClassNames="ml-auto"
                        id="isAccessibilityMaintainedOnNavigation"
                        isChecked={isAccessibilityMaintainedOnNavigation}
                        labelText=""
                        onChange={() => handleApiKeyMaintainAccessibilityChange(selectedApiKey, !isAccessibilityMaintainedOnNavigation)}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section className="pb-8">
                <h3 className="mb-2">Activation</h3>

                <div className="flex items-center">
                  <div className="flex flex-1">{statusText}</div>
                  <div className="flex pl-4">
                    <ToggleSwitch
                      id="status"
                      isChecked={status === 'ACTIVATE'}
                      labelText=""
                      onChange={() => handleApiKeyStatusChange(selectedApiKey, status)}
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="flex justify-center">
              <Button size="xs" text="Delete API key" variant="danger" onClick={() => handleDeleteApiKeyButtonClick()} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { GeneralView };
